
import { Options, Vue } from 'vue-class-component';
import { Watch} from 'vue-property-decorator'
import MapWrapper from '@/components/MapWrapper.vue'; // @ is an alias to /src
import DialogBox from '@/components/DialogBox.vue'; // @ is an alias to /src
import ToastNotify from '@/components/ToastNotify.vue'; // @ is an alias to /src
import { Leitner } from '../Leitner'
import { event } from 'vue-gtag'
import { itemData, itemId} from '@/itemType';

@Options({
  props: {
    "id":String,
    "geojsons":Object,
    //"dataJSON":String,
    "info": Object,
    "credits": String,
    "maxScale" : Number,
    "overwrapText" : String,
  },
  components: {
    MapWrapper,
    DialogBox,
    ToastNotify,
  },
})

export default class MapQuiz extends Vue {
  id!:string;//Leitner登録用のmap自体のid
  geojsons!: any;
  //dataJSON!: string;
  info!: any;

  mapWrapper!: MapWrapper;
  leitner!:Leitner;
  items!:itemData[];
  groups:{group:string,selected:boolean}[]=[];//出題グループが選択済みかどうか

  saveDataDialog!:DialogBox;
  answerDialog!:DialogBox;
  finishDialog!:DialogBox;
  selectDialog!:DialogBox;
  hintToast!:ToastNotify;
  testingId!:itemId;
  credits!:string;

  answerTimerId!:number;
  hintTimerId!:number;
  hintCount=0;
  fromHome=false;
  maxScale!:number;

  overwrapText="";
  
  async mounted(): Promise<void> {
    this.mapWrapper = this.$refs.mapWrapper as MapWrapper;
    this.saveDataDialog = this.$refs.saveDataDialog as DialogBox;
    this.selectDialog = this.$refs.selectDialog as DialogBox;
    this.answerDialog = this.$refs.answerDialog as DialogBox;
    this.finishDialog=this.$refs.finishDialog as DialogBox;
    this.hintToast=this.$refs.hintToast as ToastNotify;
    
    this.items;// = this.info.items;
    this.groups = this.info?this.info.groups.map((group:string)=>({group:group,selected:false})):[]
    
    if(Leitner.isSaved(this.id)){
      this.saveDataDialog.open();
    }else{
      this.selectItems();
    }

    const referrer = (this.$router as any)["referrer"];
    if(referrer.name=="Home"){
      this.fromHome=true;
    }
  }

  selectItems(){
    if(this.groups.length>1){
      this.selectDialog.open();
    }else{
      this.groups[0].selected=true;
      this.start();
    }
  }

  freshStart(){
    this.saveDataDialog.close();
    Leitner.resetData(this.id);

    event('reset', {
      event_category: "mapQuiz",
      event_label: "reset",
    });
    this.selectItems();
  }
  set selectAll(selectAll:boolean|undefined){
    if(selectAll===undefined) return;
    this.groups.forEach(group=>group.selected=selectAll);
  }
  get selectAll():boolean|undefined{
    if(this.groups.reduce((pre,group)=>pre && group.selected,true)){
      return true;
    }else if(this.groups.reduce((pre,group)=>pre && !group.selected,true)){
      return false;
    }else{
      return undefined;
    }
  }
  selectAndStart(){
    const selectedGroup = this.groups.filter(group=>group.selected).map(group=>group.group);
    this.items = this.info.items.filter((item:any)=>selectedGroup.includes(item.group));
    if(this.items.length>0){
      this.selectDialog.close();
      this.start();
    }else{
      this.selectDialog.refuse();
      const selectSome = this.$refs.selectSome as ToastNotify;
      selectSome.open();
    }
  }
  start(){
    this.saveDataDialog.close();
    if(!this.items){
      this.items=this.info.items;
    }
    if(Leitner.isSaved(this.id)){
      this.leitner = new Leitner(this.id)

      event('start', {
        event_category: "mapQuiz",
        event_label: "start_with_save_data",
      });
    }else{
      this.leitner = new Leitner(this.id,this.items.map((i:any)=>i.id))

      event('start', {
        event_category: "mapQuiz",
        event_label: "start_without_save_data",
      });
    }

    this.next().then(()=>{
      const answer = this.items.find((i:any)=>i.id==this.testingId);
      this.$emit("updateAnswer",answer);
    });
  }

  next(passLastId:boolean|undefined=undefined):Promise<void>{
    return new Promise((resolve)=>{
      const id = this.testingId = this.leitner.next(passLastId) as itemId;
      if(!id){
        Leitner.resetData(this.id);
        this.finishDialog.open();


        event('finish', {
          event_category: "mapQuiz",
          event_label: "finish",
        });
      }
      this.mapWrapper.learningIds = this.leitner.unlearntIds;
      this.mapWrapper.learntIds = this.leitner.learntIds;
      this.mapWrapper.activeIds = this.leitner.activeIds;
      this.mapWrapper.showCountry(id as string);

      console.log(this.items.find((i:itemData)=>i.id==this.testingId));

      this.hintTimerId = setTimeout(()=>this.hint(),this.hintCount==0?5*1000:30*1000);
      if(this.$store.state.autoAnswer){
        this.answerTimerId = setTimeout(()=>this.openAnswer(),this.$store.state.thinkingTime*1000);
      }
      resolve();
    });
  }
  hint(){
    clearTimeout(this.hintTimerId);
    this.hintToast.open();
    this.hintCount++;
  }
  openAnswer(){
    clearTimeout(this.answerTimerId);
    clearTimeout(this.hintTimerId);
    this.hintToast.close();
    if(!this.testingId) return;
    this.answerDialog.open("down");
  }
  remembered(){
    Promise.all([
      this.next(true),
      this.answerDialog.close("down")
    ]).then(()=>{
      const answer = this.items.find((i:any)=>i.id==this.testingId);
      
      this.$emit("updateAnswer",answer);
    });
  }
  forgotten(){
    Promise.all([
      this.next(false),
      this.answerDialog.close("up")
    ]).then(()=>{
      const answer = this.items.find((i:any)=>i.id==this.testingId);
      this.$emit("updateAnswer",answer)
    });
  }
  gotoHome(){
    this.$router.push('/');
    /*
    if(this.fromHome==true){
      this.$router.back();
    }else{
      this.$router.push('/');
    }
    */
  }

  get autoAnswer(){
    return this.$store.state.autoAnswer;
  }

  @Watch('autoAnswer')
  onAutoAnswerChanged(value: string, oldValue: string) {
    if(value){
      this.answerTimerId = setTimeout(()=>this.openAnswer(),this.$store.state.thinkingTime*1000);
    }else{
      clearTimeout(this.answerTimerId);
    }
  }
}


