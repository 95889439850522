
import { Options, Vue } from 'vue-class-component';
const hiragana = ['あ', 'い', 'う', 'え', 'お', 'か', 'き', 'く', 'け', 'こ', 'さ', 'し', 'す', 'せ', 'そ', 'た', 'ち', 'つ', 'て', 'と', 'な', 'に', 'ぬ', 'ね', 'の', 'は', 'ひ', 'ふ', 'へ', 'ほ', 'ま', 'み', 'む', 'め', 'も', 'や', 'ゆ', 'よ', 'ら', 'り', 'る', 'れ', 'ろ', 'わ', 'ゐ', 'ゑ', 'を', 'ん','が', 'ぎ', 'ぐ', 'げ', 'ご', 'ざ', 'じ', 'ず', 'ぜ', 'ぞ', 'だ', 'ぢ', 'づ', 'で', 'ど', 'ば', 'び', 'ぶ', 'べ', 'ぼ', 'ぱ', 'ぴ', 'ぷ', 'ぺ', 'ぽ', 'ぁ', 'ぃ', 'ぅ', 'ぇ', 'ぉ', 'っ', 'ゃ', 'ゅ', 'ょ'];
const katakana = ['ア', 'イ', 'ウ', 'エ', 'オ', 'カ', 'キ', 'ク', 'ケ', 'コ', 'サ', 'シ', 'ス', 'セ', 'ソ', 'タ', 'チ', 'ツ', 'テ', 'ト', 'ナ', 'ニ', 'ヌ', 'ネ', 'ノ', 'ハ', 'ヒ', 'フ', 'ヘ', 'ホ', 'マ', 'ミ', 'ム', 'メ', 'モ', 'ヤ', 'ユ', 'ヨ', 'ラ', 'リ', 'ル', 'レ', 'ロ', 'ワ', 'ヰ', 'ヱ', 'ヲ', 'ン', 'ガ', 'ギ', 'グ', 'ゲ', 'ゴ', 'ザ', 'ジ', 'ズ', 'ゼ', 'ゾ', 'ダ', 'ヂ', 'ヅ', 'デ', 'ド', 'バ', 'ビ', 'ブ', 'ベ', 'ボ', 'パ', 'ピ', 'プ', 'ペ', 'ポ', 'ァ', 'ィ', 'ゥ', 'ェ', 'ォ', 'ッ', 'ャ', 'ュ', 'ョ'];

@Options({
  props: {
    name:String,
    yomi:String,
  },
  components: {
  },
})

export default class LocationRuby extends Vue {
  name!:string;
  yomi!:string;
  
  get articulatedNames(){
    return this.getArticulatedNames(this.name,this.yomi);
  }
  getArticulatedNames(name:string,yomi:string):({name:string,yomi:string}[]){
    if(!name || !yomi) return [];
    //名前と読みが一致していれば
    if(name===yomi) return[{name:name,yomi:""}]

    //（）の前後で分割
    if(name.indexOf("（")+name.indexOf("）")>=0){
      let names = [name];
      names = names.map(n=>n.split("（").map((n1,i)=>i==0?n1:["（",n1]).flat()).flat();
      names = names.map(n=>n.split("）").map((n1,i)=>i==0?n1:["）",n1]).flat()).flat();

      let yomis = [yomi];
      yomis = yomis.map(n=>n.split("（").map((n1,i)=>i==0?n1:["（",n1]).flat()).flat();
      yomis = yomis.map(n=>n.split("）").map((n1,i)=>i==0?n1:["）",n1]).flat()).flat();

      return names.map((name,i)=>{
        const sub = this.getArticulatedNames(name,yomis[i]);
        return sub
      }).flat()
    }
    //分類名で終わる場合は
    const locationTitles = [
      {kanji:"都",yomi:"と"},
      {kanji:"道",yomi:"どう"},
      {kanji:"府",yomi:"ふ"},
      {kanji:"県",yomi:"けん"},
      {kanji:"市",yomi:"し"},
      {kanji:"区",yomi:"く"},
      {kanji:"町",yomi:"ちょう"},
      {kanji:"町",yomi:"まち"},
      {kanji:"村",yomi:"そん"},
      {kanji:"村",yomi:"むら"},
    ]
    for(const title of locationTitles){
      if(
        (name.lastIndexOf(title.kanji)+title.kanji.length)==name.length &&
        (yomi.lastIndexOf(title.yomi)+title.yomi.length)==yomi.length
      ){
        const pure_name = name.slice(0,name.length-title.kanji.length);
        const pure_yomi = yomi.slice(0,yomi.length-title.yomi.length);
        const articulated_names = this.getArticulatedNames(pure_name,pure_yomi);
        articulated_names.push({name:title.kanji,yomi:title.yomi});
        return articulated_names;
      }
    }

    let articulatedNames:{mojishu:(0|1|2),name:string}[]=[];
    
    let mojiShu:(0/*漢字*/|1/*ひらがな*/|2/*カタカナ*/)=0;
    let oldMojiShu:(0/*漢字*/|1/*ひらがな*/|2/*カタカナ*/)=0;
    let articulatedName = "";
    for(const char of name.split("")){
      if(hiragana.includes(char)){
        mojiShu=1;
      }else if(katakana.includes(char)){
        mojiShu=2;
      }else{
        mojiShu=0;
      }
      
      if(mojiShu===oldMojiShu){
        articulatedName = articulatedName + char;
      }else{
        articulatedNames.push({
          mojishu:oldMojiShu,
          name:articulatedName
        });
        articulatedName=char;
      }
      oldMojiShu = mojiShu;
    }
    articulatedNames.push({
      mojishu:oldMojiShu,
      name:articulatedName
    });
    articulatedNames = articulatedNames.filter(a=>a);
    
    //ひらがな・かたかなの文節がでふりがなが中にたかだか一つだけあるときだけ区切る。
    if(articulatedNames.some(articulatedName=>{
      if(articulatedName.mojishu==0) return false;
      if(articulatedName.mojishu==1) return yomi.split(articulatedName.name).length!=2;
      if(articulatedName.mojishu==2) return yomi.split(this.katakana2hiragana(articulatedName.name)).length!=2;
    })){
      return [{name,yomi}];
    }

    if(articulatedNames.length>1){
      const articulatedYomis:string[] = [];
      let yomi1 = yomi;
      articulatedNames.forEach((articulatedName,i)=>{
        if(articulatedName.mojishu==1){
          articulatedYomis.push("");
          yomi1 = yomi1.slice(articulatedName.name.length);
        }else if(articulatedName.mojishu==2){
          if(articulatedName.name.length==1){
            articulatedYomis.push(yomi1.slice(0,articulatedName.name.length));
          }else{
            articulatedYomis.push("");
          }
          yomi1 = yomi1.slice(articulatedName.name.length);
        }else{
          if(i+1==articulatedNames.length){
            articulatedYomis.push(yomi1);
          }else{
            const nextYomi = articulatedNames[i+1];
            const nextPos = nextYomi.mojishu==1
              ? yomi1.indexOf(nextYomi.name)
              : yomi1.indexOf(this.katakana2hiragana(nextYomi.name));
            articulatedYomis.push(yomi1.slice(0,nextPos));
            yomi1 = yomi1.slice(nextPos);
          }
        }
      });
      const result = articulatedNames.map((articulatedName,i)=>({
        name:articulatedName.name,
        yomi:articulatedYomis[i]
      }))
      return result;
    }
    

    return [{name,yomi}];
  }
  katakana2hiragana(kString:string){
    let hString="";
    for(const char of kString.split("")){
      const i = katakana.indexOf(char);
      if(i>=0){
        hString = hString + hiragana[i];
      }else{
        hString = hString + char;
      }
    }
    return hString;
  }
}
