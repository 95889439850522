"use strict";

//https://en.wikipedia.org/wiki/Leitner_system

type item={id:string,b:number,l:(0|1),t:(0|1)};//bはライトナーの箱番号。lは前回正答したかどうか(正答したら1)。tは出題範囲かどうか（出題範囲だと1）


export class Leitner{
    boxNumber:number;//ライトナーシステムで言う箱の数
    items:item[];//出題するid集。localstorageの容量削減のため出題項目のデータは別に用意しstringなidを使う運用を想定

    testingId:string|undefined = undefined;//前回出題されたid
    testingBoxId:number;//出題中のboxNumber
    lsKey:string;//localstorageに保存する場合のkey

    static get prefix(){
        return "leitner-0-";
    }

    static isSaved(leitnerId:string){
        const lsKey = Leitner.prefix+leitnerId;
        if(localStorage[lsKey]!==undefined){
            const items = JSON.parse(localStorage[lsKey]);
            const learningItems = items.filter((item:item) => item.t==1);
            return learningItems.length>0;
        }
        return false;
    }

    static resetData(leitnerId:string){
        const lsKey = Leitner.prefix+leitnerId;
        const items = JSON.parse(localStorage[lsKey]);
        items.forEach((item:item) => {
            item.l=0;
            item.t=0;
        });
        localStorage[lsKey] = JSON.stringify(items);
    }

    constructor(leitnerId:string,ids:string[]|undefined=undefined){
        const maxBoxNumber=3;
        const defaultBoxNumber=0;
        this.testingBoxId = 0;
        this.lsKey = Leitner.prefix+leitnerId;
        this.boxNumber = maxBoxNumber;
        
        if(ids!=undefined){
            if(localStorage[this.lsKey]!==undefined){
                const items = JSON.parse(localStorage[this.lsKey]);
                this.items = items.filter((item:any)=>item.id).map((item:any)=>({
                    id:item.id,
                    b:item.b||defaultBoxNumber,
                    l:0,
                    t:ids.includes(item.id)?1:0,
                }))
                ids.forEach(id=>{
                    if(!this.items.find(item=>item.id==id)){
                        this.items.push({id:id,b:defaultBoxNumber,l:0,t:1});
                    }
                })
            }else{
                this.items = ids.filter(id=>id!==undefined).map(id=>({id:id,b:defaultBoxNumber,l:0,t:1}));
            }
            
            localStorage[this.lsKey] = JSON.stringify(this.items);
        }else{
            const saveDataJSON = localStorage[this.lsKey];
            if(saveDataJSON==null) throw new Error("no save data")
            const saveData:item[] = JSON.parse(saveDataJSON);
            this.items = saveData;
        }

        console.log("Items to learn:",this.items)
    }
    hasNext():boolean{
        return this.learntItems.length<this.learningItems.length;
    }
    next(passLastId:boolean|undefined=undefined):string|false{
        const lastTestingId = this.testingId;

        //前回
        const testingItem = this.items.find(item=>item.id==this.testingId);
        if(testingItem!==undefined){
            if(passLastId===true){
                testingItem.b = Math.min(testingItem.b+1,this.boxNumber-1);
                testingItem.l = 1;
            }else if(passLastId===false){
                testingItem.b = 0;
                testingItem.l = 0;
            }
            localStorage[this.lsKey] = JSON.stringify(this.items)
        }
        

        function pickRandomFromArray(items:(any[])):any{
            return items[Math.floor(Math.random()*items.length)];
        }
        

        if(this.unlearntItems.length>1){
            return this.testingId = pickRandomFromArray(
                this.unlearntIds.filter((id)=>id!=lastTestingId)
            );
        }else if(this.unlearntItems.length==1){
            if(lastTestingId==this.unlearntIds[0]){
                return this.testingId = pickRandomFromArray(this.learntIds);
            }else{
                return this.testingId = this.unlearntIds[0];
            }
        }

        //全て覚えた
        console.log("All items has been learnt:",this.learningItems);
        //this.learningItems.forEach((item:item)=>item.t=0);
        return false;
    }
    get learningItems(){
        return this.items.filter((item:item)=>item.t==1);
    }
    get learningIds(){
        return this.learningItems.map((item:item)=>item.id);
    }
    get learntItems(){
        return this.learningItems.filter((item:item)=>item.l==1);
    }
    get learntIds(){
        return this.learntItems.map((item:item)=>item.id);
    }
    get unlearntItems(){
        return this.learningItems.filter((item:item)=>item.l==0);
    }
    get unlearntIds(){
        return this.unlearntItems.map((item:item)=>item.id);
    }
    get activeItems(){
        return this.learningItems.filter((item:item)=>item.id==this.testingId);
    }
    get activeIds(){
        return this.activeItems.map((item:item)=>item.id);
    }
}