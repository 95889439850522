
import { Watch} from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component';
import D3Map from '@/mapComponents/D3Map.vue'; // @ is an alias to /src
import LeafletMap from '@/mapComponents/LeafletMap.vue'; // @ is an alias to /src
import MapAbstract from '@/mapComponents/mapAbstract';
import { itemId } from '@/itemType';

@Options({
  props: {
    geojsons: Object,
    credits: String,
    maxScale: Number,
    overwrapText: String,
  },
  components:{
    D3Map,
    LeafletMap
  }
})
export default class MapWrapper extends Vue {
  geojsons!: {leaflet?:string,d3?:string};
  maxScale!: number;
  credits!: string;
  _learningIds!:(string|number)[];
  _learntIds!:(string|number)[];
  _activeIds!:(string|number)[];
  
  mapType:("d3"|"photo") = "d3";
  mapTypeOption = ["d3"];

  overwrapText!:string;
  moving=false;
  
  //learningIds!: string|number[];

  mounted(): void {

    if(this.geojsons.leaflet){
      this.mapTypeOption.push("photo")
    }
  }
  get mapElement():MapAbstract{
    console.log("getter mapElement")
    if(this.mapType == "d3"){
      return this.$refs.d3map as D3Map;
    }else{
      return this.$refs.leafletMap as LeafletMap;
    }
  }

  showCountry(code:itemId){
    this.moving = true;
    return this.mapElement.showCountry(code).then(()=>this.moving=false);
  }

  zoomIn(){
    this.mapElement.zoomIn();
  }
  zoomOut(){
    this.mapElement.zoomOut();
  }

  set learningIds(learningIds:(string|number)[]) {
    if(this.mapElement) this.mapElement.learningIds = learningIds;
    this._learningIds = learningIds;
  }
  get learningIds(){
    return this._learningIds;
  }
  set learntIds(learntIds:(string|number)[]) {
    if(this.mapElement) this.mapElement.learntIds = learntIds;
    this._learntIds = learntIds;
  }
  get learntIds(){
    return this._learntIds;
  }
  set activeIds(activeIds:(string|number)[]) {
    if(this.mapElement) this.mapElement.activeIds = activeIds;
    this._activeIds = activeIds;
  }
  get activeIds(){
    return this._activeIds || [];
  }
  prepareNewMap(mapElement:any){
    console.log("prepareNewMap",this.mapType,mapElement);
    if(!this._activeIds)return;
    mapElement.activeIds = this._activeIds;
    mapElement.learningIds = this._learningIds;
    mapElement.learntIds = this._learntIds;
    if(this.activeIds.length>0){
      this.showCountry(this.activeIds[0])
    }
  }

  @Watch('mapType')
  onColorfulChange(value: any, oldValue: any) {
    console.log(`map changed from ${value} to ${oldValue}`);
  }

  get showKanji():boolean{
    return this.$store.state.showKanji;
  }
  /*
  @Watch('showKanji')
  onShowKanjiChange(value: any, oldValue: any) {
    console.log(`map changed from ${value} to ${oldValue}`);
  }
  */
}


