"use strict";

import * as d3 from "d3"
import { active } from "d3";
import * as L from "leaflet";
import 'leaflet.vectorgrid';
//import vectorTileLayerStyles from './vectorTileLayerStyles.json';

export class LeafletClass{
    private lat:number
    private lon:number
    private _scale:number
    private gamma
    src:string //ベースとなるtopojsonの絶対パス
    //public mapReady:Promise<void>;
  
    private wrapper:HTMLElement;
    private map:any;
    public mapReady:Promise<void>;
    private features:any;
    
    private animationId:number|undefined=undefined//アニメーションID（新しいアニメーションの開始時に前回のアニメーションが実行中だった場合強制終了）
    private mapColors:any;
    private _darkMode = false;
    private _colorfulMap = false;

    public learningIds:(string|number)[]=[];//学習中
    public learntIds:(string|number)[]=[];//学習ずみ
    public activeIds:(string|number)[]=[];//出題中

    private activeLayer:any;
    private polygonLayer: any;

    private widthRate = 1;

    maxZoomLevel:number;
  
    constructor({
      src,
      wrapper,
      maxZoomLevel=30,
      darkMode,
      colorfulMap,
    }:any){
      //初期表示の位置，縮尺(_scale==1で画面内に地球がすっぽり収まるサイズ)
      this._darkMode = darkMode;
      this._colorfulMap = colorfulMap;
      this.changeColorMode();

      this.lat=37.5;
      this.lon=137.7;
      this._scale=1;
      this.gamma=0;
      this.src = src;
      this.maxZoomLevel = maxZoomLevel;
      this.wrapper = wrapper;
      
  
      //地図の準備
      const map = L.map(this.wrapper,{
          center: [this.lat,this.lon],
          zoom: 10,
          zoomControl:false,
      })
      this.map = map;
      
      const layer_photo = L.tileLayer(
        'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
        {attribution:"<span style='color:red;'>地理院タイル！！</span>" });

      layer_photo.addTo(map);

      //ポリゴンデータ読み込み
      this.mapReady = fetch(this.src)
        .then(response => response.json())
        //.then(topoJSON => topojson.feature(topoJSON, topoJSON.objects.map))
        .then((myGeoJSON:any)=>{
          console.log(myGeoJSON)
          this.features=myGeoJSON.features;

          this.polygonLayer = L.geoJSON(this.features, {
            style: (feature:any)=>{
              return {
                color: "black",
                weight: 1
              };
            },
          })/*.bindPopup(function (layer:any) {
            console.log(layer.feature.properties)
            return layer.feature.properties.CITY_NAME+"/"+layer.feature.properties.S_NAME;
          })*/.addTo(map);
          console.log(this.polygonLayer);


        this.map.flyToBounds(this.polygonLayer.getBounds().pad(0));

        })
        .catch(e=>console.log(e));
    }
    
    showCountry(code:string|number):Promise<void>{
      
      return new Promise((resolve) => {
        console.log("LeafletMap.ts showCountry",code);
        
        this.mapReady.then(()=>{
          //指定コードの国を画面中央に持ってくると同時に適度な縮尺に変更
          const activeFeature = this.features.find((feature:any)=>feature.properties.geoCode==code);
          if(activeFeature==undefined) return;

          this.polygonLayer.eachLayer((layer:any)=>{
            //if(!Object.keys(layer.featre).includes("properties")) return;
            const geoCode = layer.feature.properties.geoCode;
            let fillColor = this.mapColors.otherCountries;
            let weight = 1;
            let opacity = 1;
            let fillOpacity = 0.8;
            if(this.activeIds.includes(geoCode)){
              opacity = 0;
              fillOpacity = 0;
              fillColor = this.mapColors.activeCountries;
              weight = 0;
            }else if(this.learningIds.includes(geoCode)){
              fillColor = this.mapColors.learningCountries;
              fillOpacity = 0.4;
            }else if(this.learntIds.includes(geoCode)){
              fillColor = this.mapColors.learntCountries;
              fillOpacity = 0.8;
            }

            try{
              layer.setStyle({
                color:"black",//this.mapColors.boundary,
                fillColor:fillColor,
                weight:weight,
                opacity:opacity,
                fillOpacity:fillOpacity,
              });
            }catch(e){
              void(0);
            }
          });
          
          if(this.activeLayer){
            this.activeLayer.remove();
          }

          this.activeLayer = L.geoJSON(activeFeature, {
              style: (feature)=>{
                console.log(activeFeature)
                return {
                  opacity:1,
                  fillOpacity:0,
                  color: "red",
                  weight:5,
                };
              }
          })
          this.activeLayer.addTo(this.map);

          this.map.flyToBounds(this.activeLayer.getBounds().pad(1));
          setTimeout(()=>resolve(),1000);
        })
      });
    }

    zoomIn(){
      return this.map.zoomIn();
    }

    zoomOut(){
      return this.map.zoomOut();
    }
    fitMap(){
      return;
    }
    get darkMode(){
      return this._darkMode;
    }
    set darkMode(value){
      this._darkMode = value;
      this.changeColorMode();
    }
    get colorfulMap(){
      return this._colorfulMap;
    }
    set colorfulMap(value){
      this._colorfulMap = value;
      this.changeColorMode();
    }
    changeColorMode(){
      if(!this.darkMode){
        if(this.colorfulMap){
          this.mapColors = mapColors["lightColorful"];
          console.log("colorMode: lightColorful");
        }else{
          this.mapColors = mapColors["lightColorless"];
          console.log("colorMode: lightColorless");
        }
      }else{
        if(this.colorfulMap){
          this.mapColors = mapColors["darkColorful"];
          console.log("colorMode: darkColorful");
        }else{
          this.mapColors = mapColors["darkColorless"];
          console.log("colorMode: darkColorless");
        }
      }
      try{
        //this.render();
      }catch(e){
        void(e);
      }
    }
  }


const mapColors={
  "lightColorful":{
    space:"#aaaaff",//宇宙
    ocean:"#ccccff",
    equator:"#cc2828",
    activeCountries:"#ff0000",
    activeBorder:"#ffffff",
    learningCountries:"#197f19",
    stroke:"#ffffff",
    learntCountries:"#667f66",
    otherCountries:"#7f7f7f",
    geo:"#bbbbbb",
    geoBold:"#aaaaaa",
    border:"#ffffff",
  },
  "lightColorless":{
    space:"#ffffff",//宇宙
    ocean:"#eeeeee",
    equator:"#f99",
    activeCountries:"#BF0000",
    activeBorder:"#ffffff",
    learningCountries:"#777",
    stroke:"#111133",
    learntCountries:"#999",
    otherCountries:"#ddd",
    geo:"#333333",
    geoBold:"#222222",
    border:"#111133",
  },
  "darkColorless":{
    space:"#222222",//宇宙
    ocean:"#000000",
    equator:"#663D3D",
    activeCountries:"#880000",
    activeBorder:"#ffffff",
    learningCountries:"#222222",
    stroke:"#eeeeee",
    learntCountries:"#333333",
    otherCountries:"#555555",
    geo:"#444444",
    geoBold:"#555555",
    border:"#444444",
  },
  "darkColorful":{
    space:"#000033",//宇宙
    ocean:"#4C4C7F",
    equator:"#663D3D",
    activeCountries:"#BF0000",
    activeBorder:"#ffffff",
    learningCountries:"#476647",
    stroke:"#111133",
    learntCountries:"#5B665B",
    otherCountries:"#666666",
    geo:"#333333",
    geoBold:"#222222",
    border:"#111133",
  }
}

const vectorTileLayerStyles = {
  "contour" : {
    color: "#ddf",
    weight: 1
  },
  "building" : {
    //color: "purple",
    weight: 0
  },
  "road" : {
    color: "gray",
    weight: 2
  },
  "railway" : {
    color: "purple",
    weight: 2,
    dashArray: '10 10',
    dashOffset: '0',
  },
  "searoute" : {
    color: "lightblue",
    weight: 1
  },
  
  "transl" : {
    weight:0
  },
  //道路標識など
  "transp" : {
    weight:0
  },
  
  "boundary" : {
    weight:0
  },
  "coastline" : {
    color: "lightblue",
    weight: 1
  },
  "waterarea" : {
    color: "lightblue",
    fillColor:"lightblue",
    fillOpacity:"lightblue",
    weight: 1,
  },
  "river" : {
    color: "lightblue",
    weight: 1
  },
  "lake" : {
    color: "lightblue",
    weight: 1
  },
  
  "landforma" : {
    weight:0
  },
  "landforml" : {
    weight:0
  },
  "landformp" : {
    weight:0
  },
  "structurea" : {
    weight:0
  },
  // 送電線、
  "structurel" : {
    weight:0
  },
  
  "elevation" : {
    weight:0
  },
  "label" : {
    weight:0
  },
  "symbol" :{
    radius:0,
    weight:0,

  }
}