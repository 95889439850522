import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-607f3012"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-inner" }
const _hoisted_2 = {
  class: "dialog-buttons",
  ref: "buttons"
}
const _hoisted_3 = { style: {"font-weight":"bold"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dialog-overlay", _ctx.fadeClass]),
    ref: "dialog",
    onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.overlayClick && _ctx.overlayClick(...args)), ["stop"])),
    style: _normalizeStyle(_ctx.overlayCSS)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["dialog-wrapper", _ctx.wrapperClass]),
      ref: "dialogWrapper",
      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (void(0)), ["stop"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["dialog-contents", {scrollable:_ctx.scrollable}]),
          ref: "dialogContents"
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.yesButton && _ctx.yesButton(...args)), ["stop"]))
          }, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.yLabel), 1)
          ]),
          (_ctx.nLabel)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.noButton && _ctx.noButton(...args)), ["stop"]))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.nLabel), 1)
              ]))
            : _createCommentVNode("", true)
        ], 512)
      ])
    ], 2)
  ], 6))
}