
import { Options, Vue } from 'vue-class-component';
import { Watch} from 'vue-property-decorator'
import { D3Globe } from './D3Globe';
import LoadingEffect from '@/components/LoadingEffect.vue'; // @ is an alias to /src
import MapAbstract from './mapAbstract';
import { itemId} from '@/itemType';

@Options({
  props: {
    src: String,
    credits: String,
    maxScale: Number,
  },
  components:{
    LoadingEffect,
  }
})
export default class D3Map extends MapAbstract {
  canvas!: HTMLCanvasElement;
  d3globe!:D3Globe;
  src!: string;
  maxScale!: number;
  credits!: string;
  mapReady=false;
  
  created():void{
    console.log("D3Map.vue created!");
  }

  mounted(): void {
    console.log("D3Map.vue mounted!");
    const boundary = this.$el.getBoundingClientRect();
    this.canvas = this.$refs.canvas as HTMLCanvasElement;

    this.canvas.width = boundary.width * window.devicePixelRatio;
    this.canvas.height = boundary.height * window.devicePixelRatio;
    this.d3globe = new D3Globe({
      canvas:this.canvas,
      src:this.src,
      maxScale:this.maxScale || undefined,
      darkMode:this.$store.state.darkMode,
      colorfulMap:this.$store.state.colorfulMap,
    });

    this.d3globe.mapReady.then(()=>{
      this.mapReady=true;
      this.$emit("map-created",this);
    })

    window.addEventListener("resize",this.resize)
  }
  showCountry(code:string|number):Promise<void>{
    return this.d3globe.showCountry(code);
  }
  resize(): void{
    const boundary = this.$el.getBoundingClientRect();
    const canvas = this.canvas;
    canvas.width = boundary.width * window.devicePixelRatio;
    canvas.height = boundary.height * window.devicePixelRatio;
    this.d3globe.fitMap();
  }
  
  
  set learningIds(learningIds:(string|number)[]) {
    this.d3globe.learningIds = learningIds;
  }
  get learningIds(){
    return this.d3globe.learningIds;
  }
  set learntIds(learntIds:itemId[]) {
    this.d3globe.learntIds = learntIds;
  }
  get learntIds(){
    return this.d3globe.learntIds;
  }
  set activeIds(activeIds:(string|number)[]) {
    console.log("D3Map.vue set activeIds",activeIds);
    this.d3globe.activeIds = activeIds;
  }
  get activeIds(){
    return this.d3globe.activeIds;
  }
  zoomIn(){
    this.d3globe.scale = this.d3globe.scale * 2;
  }
  zoomOut(){
    this.d3globe.scale = this.d3globe.scale / 2;
  }
  get darkMode(){
    return this.$store.state.darkMode;
  }
  get colorfulMap(){
    return this.$store.state.colorfulMap;
  }
  @Watch('darkMode')
  onDarkModeChanged(value: string, oldValue: string) {
    this.d3globe.darkMode = value as unknown as boolean;
  }

  @Watch('colorfulMap')
  onColorfulChange(value: string, oldValue: string) {
    this.d3globe.colorfulMap = value as unknown as boolean;
  }
}


