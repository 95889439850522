
import { Options, Vue } from 'vue-class-component';
import { Watch} from 'vue-property-decorator'
import { LeafletClass } from './LeafletClass';
import LoadingEffect from '@/components/LoadingEffect.vue'; // @ is an alias to /src
import MapAbstract from './mapAbstract';

@Options({
  props: {
    src: String,
    credits: String,
    maxScale: Number,
  },
  components:{
    LoadingEffect,
  }
})
export default class LeafletMap extends MapAbstract {
  //width!: number;
  //height!: number;
  wrapper!: HTMLElement;
  LeafletClass!:LeafletClass;
  src!: string;
  maxScale!: number;
  credits!: string;
  mapReady=false;
  
  //learningIds!: string|number[];
  created():void {
    console.log("Leaflet.vue created!");
  }
  mounted(): void {
    console.log("Leaflet.vue mounted!");
    console.log("src",this.src)
    this.wrapper = this.$refs.leaflet_containe as HTMLElement;

    this.LeafletClass = new LeafletClass({
      wrapper:this.wrapper,
      src:this.src,
      maxScale:this.maxScale || undefined,
      darkMode:this.$store.state.darkMode,
      colorfulMap:this.$store.state.colorfulMap,
    });

    this.LeafletClass.mapReady.then(()=>{
      this.mapReady=true;
      this.$emit("map-created",this);
    });
  }
  showCountry(code:string|number):Promise<void>{
    return this.LeafletClass.showCountry(code);
  }

  zoomIn(){
    this.LeafletClass.zoomIn();
  }
  zoomOut(){
    this.LeafletClass.zoomOut();
  }
  
  set learningIds(learningIds:(string|number)[]) {
    this.LeafletClass.learningIds = learningIds;
  }
  get learningIds(){
    return this.LeafletClass.learningIds;
  }
  set learntIds(learntIds:(string|number)[]) {
    this.LeafletClass.learntIds = learntIds;
  }
  get learntIds(){
    return this.LeafletClass.learntIds;
  }
  set activeIds(activeIds:(string|number)[]) {
    console.log("LeafletMap.vue set activeIds",activeIds);
    this.LeafletClass.activeIds = activeIds;
  }
  get activeIds(){
    return this.LeafletClass.activeIds;
  }
  
  get darkMode(){
    return this.$store.state.darkMode;
  }
  get colorfulMap(){
    return this.$store.state.colorfulMap;
  }
  @Watch('darkMode')
  onDarkModeChanged(value: any, oldValue: any) {
    this.LeafletClass.darkMode = value;
  }

  @Watch('colorfulMap')
  onColorfulChange(value: any, oldValue: any) {
    this.LeafletClass.colorfulMap = value;
  }
}


