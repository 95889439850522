"use strict";

import * as d3 from "d3"
//import { map } from "d3";
//import * as topojson from "topojson"


const mapColors={
  "lightColorful":{
    space:"#aaaaff",//宇宙
    ocean:"#ccccff",
    equator:"#cc2828",
    activeCountries:"#ff0000",
    activeBorder:"#ffffff",
    learningCountries:"#197f19",
    stroke:"#ffffff",
    learntCountries:"#667f66",
    otherCountries:"#7f7f7f",
    geo:"#bbbbbb",
    geoBold:"#aaaaaa",
    border:"#ffffff",
  },
  "lightColorless":{
    space:"#ffffff",//宇宙
    ocean:"#eeeeee",
    equator:"#f99",
    activeCountries:"#BF0000",
    activeBorder:"#ffffff",
    learningCountries:"#777",
    stroke:"#111133",
    learntCountries:"#999",
    otherCountries:"#ddd",
    geo:"#333333",
    geoBold:"#222222",
    border:"#111133",
  },
  "darkColorless":{
    space:"#222222",//宇宙
    ocean:"#000000",
    equator:"#663D3D",
    activeCountries:"#880000",
    activeBorder:"#ffffff",
    learningCountries:"#222222",
    stroke:"#eeeeee",
    learntCountries:"#333333",
    otherCountries:"#555555",
    geo:"#444444",
    geoBold:"#555555",
    border:"#444444",
  },
  "darkColorful":{
    space:"#000033",//宇宙
    ocean:"#4C4C7F",
    equator:"#663D3D",
    activeCountries:"#BF0000",
    activeBorder:"#ffffff",
    learningCountries:"#476647",
    stroke:"#111133",
    learntCountries:"#5B665B",
    otherCountries:"#666666",
    geo:"#333333",
    geoBold:"#222222",
    border:"#111133",
  }
}

export class D3Globe{
    private lat:number
    private lon:number
    private _scale:number
    private gamma
    src:string //ベースとなるtopojsonの絶対パス
    public mapReady:Promise<void>;
  
    private canvas
    private context
    private projection
    private path
    private features:any
    private graticule
    private equatorGeoJSON:any
    private primeMeridianGeoJSON:any
    private IDL:any
    private pacific:any
    private animationId:number|undefined=undefined//アニメーションID（新しいアニメーションの開始時に前回のアニメーションが実行中だった場合強制終了）
    private mapColors:any;
    private _darkMode = false;
    private _colorfulMap = false;

    public learningIds:(string|number)[]=[];//学習中
    public learntIds:(string|number)[]=[];//学習ずみ
    public activeIds:(string|number)[]=[];//出題中

    maxScale:number;
  
    constructor({
      src,
      canvas,
      maxScale=2.5,
      darkMode,
      colorfulMap,
    }:any){
      //初期表示の位置，縮尺(_scale==1で画面内に地球がすっぽり収まるサイズ)

      this._darkMode = darkMode;
      this._colorfulMap = colorfulMap;
      this.changeColorMode();
      this.lat=37.5;
      this.lon=137.7;
      this._scale=1;
      this.gamma=0;
      this.src = src;
      this.maxScale = maxScale;
  
      //canvasの準備
      this.canvas = canvas;
      this.context = this.canvas.getContext("2d");
  
      this.projection = d3.geoOrthographic().clipAngle(90);
  
      //ウィンドウ高さ，幅から球の半径決定.canvasへのwidth,heightの設定
      //this.fitMap()
  
      //座標変換用の関数準備
      this.path = d3.geoPath(this.projection).context(this.context);
  
      console.log("geojson path:",this.src)

      this.mapReady = fetch(this.src)
        .then(response => response.json())
        //.then(topoJSON => topojson.feature(topoJSON, topoJSON.objects.map))
        .then((myGeoJSON:any)=>{
          this.features=myGeoJSON.features;
          this.render()
        })
        .catch(e=>console.log(e))
      
      //15°間隔の緯線・経線
      this.graticule = d3.geoGraticule().step([15, 15]);
  
      {//地球儀関連の各種パーツ
        //赤道
        this.equatorGeoJSON=(()=>{
          const path = [];
          for (let lon = -180; lon <= 180; lon += 5) {
            path.push([lon, 0]);
          }
          return {
            "type": "Feature",
            "geometry": {
              "type": "LineString",
              "coordinates": path
            }
          };
        })();
        //本初子午線
        this.primeMeridianGeoJSON=(()=>{
          const path = [];
          for (let lat = -90; lat <= 90; lat += 5) {
            path.push([0, lat]);
          }
          return {
            "type": "Feature",
            "geometry": {
              "type": "LineString",
              "coordinates": path
            }
          };
        })();
        
        //日付変更線のgeoJSON
        Promise.all([
        fetch("/geo/ne_10m_geographic_lines.json")
          .then(response => response.json())
          .then((json)=>{
            this.IDL=json.features[5];
            this.render()
          }),
        //太平洋境界
        fetch("/geo/boundaries.geojson")
          .then(response => response.json())
          .then((json)=>{
            this.pacific=json.features;
            this.render()
          })
        ]).then(()=>{
          this.render();
        })
      }
  
      this.fitMap();
      this.render();
      /*
      //画面サイズ変更時
      window.addEventListener("resize",()=>{
        this.fitMap();
        this.render();
      })
      */
    }
    
    showCountry(code:string|number):Promise<void>{

      return new Promise((resolve) => {
        this.mapReady.then(()=>{
          //指定コードの国を画面中央に持ってくると同時に適度な縮尺に変更
          if(!this.features){
            console.log("features has not been loaded!");
            return false;
          }
          const country = this.features.find((feature:any)=>feature.properties.geoCode==code);
          if(country==undefined) return;
    
          //国の中心（首都ではなく図形的な中心）
          const cent = d3.geoCentroid(country);
          
          //国の重心を中心に据えたprojectionを一旦用意
          const tmpProjection = d3.geoOrthographic().clipAngle(90);
          tmpProjection.rotate([-cent[0], -cent[1], 0]).scale(1);
          const tmpPath = d3.geoPath(tmpProjection).context(this.context);
          //国の重心を中心に据えたprojectionにおいて国の表示範囲boundを計算
          const bounds = tmpPath.bounds(country);
          //boundの長辺
          const longerBound = Math.max(bounds[1][0]-bounds[0][0],bounds[1][1]-bounds[0][1])
          const _scale = (Math.abs(longerBound)!=Infinity)?Math.min(Math.max(1,1/longerBound),this.maxScale):this.maxScale;
          
          //アニメーションで指定の座標・拡大率に移行
          console.log(`moving to (${cent[0]},${cent[1]},scale:${_scale})`)
          
          resolve(this.moveTo(cent[1],cent[0],_scale));
        })
      });
    }
    
    moveTo(lat:number, lon:number, _scale=this._scale):Promise<void>{

      return new Promise((resolve) => {
        //指定の座標・縮尺にアニメートする関数
        
        //前回のアニメーションが終了していない場合，むりやりアニメーションを終了させる
        if(this.animationId!=undefined){
          cancelAnimationFrame(this.animationId)
        }
        //clearTimeout(this.timerID)
    
        //現在からの移動量を求める
        const δlat = lat - this.lat;
        const δlon = (()=>{
          let δlon = lon - this.lon;
          if(δlon>180){
            δlon = δlon - 360
          }else if(δlon<-180){
            δlon = δlon + 360
          }
          return δlon;
        })();
        
        const δscale = _scale - this._scale;
    
        //座標の更新
        this.lat = lat;
        this.lon = lon;
        this._scale = _scale;
    
        //とりあえず1000msでアニメーション時間は固定
        const duration=1000;//[ms]
        const endTS = Date.now() + duration;
    
        const rotateStep = ()=>{
          //アニメーション進行度合い（1→0）
          const rate = Math.max((endTS - Date.now())/duration,0);
          this.render(
            this.lat - δlat*rate,
            this.lon - δlon*rate,
            this._scale - δscale*rate,
          )
          if(rate>0){
            //次のフレームの描画
            this.animationId = window.requestAnimationFrame(rotateStep);
          }else{
            this.animationId = undefined;
            resolve();
          }
        }
        rotateStep();
      });
    }

    get radius(){
      const c = this.canvas;
      return (Math.min(c.width, c.height))*0.49
    }

    render(lat=this.lat,lon=this.lon,_scale=this._scale,gamma=this.gamma){
      this.context.lineWidth = 1;
      this.projection
        .rotate([-lon, -lat, gamma])
        .scale(this.radius*_scale);
  
      //前回の描画内容消去
      this.context.fillStyle = this.mapColors.space;
      this.context.rect(0, 0, this.ww, this.hh);
      this.context.fill();
  
      {
        //ベースの丸
        this.context.beginPath();
        this.context.arc(this.ww/2, this.hh/2, this.radius*_scale, 0, 2*Math.PI);
        this.context.fillStyle = this.mapColors.ocean;
        this.context.fill();
  
        //緯経線
        this.context.beginPath();
        this.path(this.graticule());
        this.context.strokeStyle = this.mapColors.geo;
        this.context.stroke();
        
        //赤道
        this.context.beginPath();
        this.path(this.equatorGeoJSON);
        this.context.strokeStyle = this.mapColors.equator;
        this.context.lineWidth = 3;
        this.context.stroke();
        
        //本初子午線
        this.context.beginPath();
        this.path(this.primeMeridianGeoJSON);
        this.context.strokeStyle = this.mapColors.geoBold;
        this.context.lineWidth = 2;
        this.context.stroke();
        
        //日付変更線
        if(this.IDL){
          this.context.beginPath();
          this.path(this.IDL);
          this.context.strokeStyle = this.mapColors.geoBold;
          this.context.lineWidth = 2;
          this.context.stroke();
        }
        //太平洋の区画
        if(this.pacific){
          this.context.beginPath();
          for(let i=0;i<this.pacific.length;i++){
            this.path(this.pacific[i]);
          }
          this.context.setLineDash([1, 2, 10, 2]);
          this.context.strokeStyle = this.mapColors.geo;
          this.context.lineWidth = 1;
          this.context.stroke();
          this.context.setLineDash([]);
        }
      }
      if(this.features){
        //各国
        let activeN;
        for(let i=0;i<this.features.length;i++){
          this.context.beginPath();
          this.path(this.features[i]);
          if(this.activeIds.includes(this.features[i].properties.geoCode)){
            this.context.fillStyle = this.mapColors.activeCountries;
          }else if(this.learningIds.includes(this.features[i].properties.geoCode)){
            this.context.fillStyle = this.mapColors.learningCountries;
          }else if(this.learntIds.includes(this.features[i].properties.geoCode)){
            this.context.fillStyle = this.mapColors.learntCountries;
          }else{
            this.context.fillStyle = this.mapColors.otherCountries;
          }
          this.context.fill();
          this.context.strokeStyle = this.mapColors.border;
          this.context.lineWidth = 1;
          this.context.stroke();
        }
        for(let i=0;i<this.features.length;i++){
          if(!this.activeIds.includes(this.features[i].properties.geoCode)) continue;

          this.context.beginPath();
          this.path(this.features[i]);
          this.context.lineJoin = "round";
          this.context.strokeStyle = "#fff4";
          this.context.lineWidth = 20;
          this.context.stroke();
          this.context.strokeStyle = this.mapColors.activeCountries+"66";
          this.context.lineWidth = 20;
          this.context.stroke();
          this.context.strokeStyle = this.mapColors.activeBorder;
          this.context.lineWidth = 2;
          this.context.stroke();
          this.context.lineJoin = "miter";
          this.context.fillStyle = this.mapColors.activeCountries;
          this.context.fill();
        }
      }

      //外周と立体感
      this.context.beginPath();
      this.context.arc(this.ww/2, this.hh/2, this.radius*_scale, 0, 2*Math.PI);
      this.context.strokeStyle = this.mapColors.space;
      this.context.lineWidth = 2;
      this.context.stroke();

      const xx=this.ww/2 - this.radius*_scale*0.1
      const yy=this.hh/2 - this.radius*_scale*0.1
      const gradient = this.context.createRadialGradient(xx, yy, 0, xx, yy, this.radius*_scale*1.2);
      gradient.addColorStop(0, '#fff1');
      gradient.addColorStop(1, '#0004');
      this.context.fillStyle = gradient;
      this.context.fill();
    }
    get ww(){
      return this.canvas.width;
    }
    set ww(ww){
      this.canvas.width = ww;
      this.fitMap();
    }
    get hh(){
      return this.canvas.height;
    }
    set hh(hh){
      this.canvas.height = hh;
      this.fitMap();
    }
    set scale(scale:number){
      this.moveTo(this.lat, this.lon, scale);
    }
    get scale():number{
      return this._scale;
    }


    fitMap(){
      this.projection
        .rotate([-this.lon, -this.lat, 0])
        .translate([this.ww/2, this.hh/2])
        .scale(this.radius*this._scale);
      this.render();
    }
    get darkMode(){
      return this._darkMode;
    }
    set darkMode(value){
      this._darkMode = value;
      this.changeColorMode();
    }
    get colorfulMap(){
      return this._colorfulMap;
    }
    set colorfulMap(value){
      this._colorfulMap = value;
      this.changeColorMode();
    }
    changeColorMode(){
      if(!this.darkMode){
        if(this.colorfulMap){
          this.mapColors = mapColors["lightColorful"];
          console.log("colorMode: lightColorful");
        }else{
          this.mapColors = mapColors["lightColorless"];
          console.log("colorMode: lightColorless");
        }
      }else{
        if(this.colorfulMap){
          this.mapColors = mapColors["darkColorful"];
          console.log("colorMode: darkColorful");
        }else{
          this.mapColors = mapColors["darkColorless"];
          console.log("colorMode: darkColorless");
        }
      }
      try{
        this.render();
      }catch(e){
        void(e);
      }
    }
    /*
    addClass(code,className){
      for(let i=0;i<this._geoData.length;i++){
        if(this._geoData[i].id==code){
          this._geoData[i].class=className;
          return true;
        }
      }
      return false;
    }*/
  }