"use strict";

import { Vue } from 'vue-class-component';
import { itemData, itemId} from '@/itemType';

export default abstract class MapAbstract extends Vue {
  abstract src: string;
  abstract maxScale: number;
  abstract credits: string;
  abstract mapReady:boolean;

  abstract showCountry(code:itemId):Promise<void>;

  abstract zoomIn():void;
  abstract zoomOut():void;

  abstract set learningIds(ids:itemId[]);
  abstract get learningIds():itemId[];

  abstract set learntIds(ids:itemId[]);
  abstract get learntIds():itemId[];

  abstract set activeIds(ids:itemId[]);
  abstract get activeIds():itemId[];
}
  