
import { Options, Vue } from 'vue-class-component';

@Options({
  props:["yLabel","nLabel","persistent"]
})
export default class DialogBox extends Vue {
  isOpen=false;
  dialogWrapper!:HTMLElement;
  dialogOverlay!:HTMLElement;
  dialogContents!:HTMLElement;
  scrollable=false;

  status:("close"|"opening"|"open"|"closing")="close";
  direction:("up"|"down"|undefined)=undefined;
  persistent!:boolean;

  duration=500;//ms
  rolling=false;

  yLabel!:string;
  nLabel!:string;

  mounted(){
    this.dialogWrapper = this.$refs.dialogWrappera as HTMLElement;
    this.dialogOverlay = this.$el as HTMLElement;
    this.dialogContents = this.$refs.dialogContents as HTMLElement;
  }
  scrollState(){
    this.scrollable = this.dialogContents.clientHeight<this.dialogContents.scrollHeight;
  }
  yesButton(){
    if(this.status=="open") this.$emit("yesButton")
  }
  noButton(){
    if(this.status=="open") this.$emit("noButton")
  }
  open(direction:"up"|"down"|undefined=undefined):Promise<void>{
    return new Promise((resolve)=>{
      this.status = "opening";
      this.direction = direction
      setTimeout(()=>{
        this.status = "open";
        this.scrollState();
        resolve();
      },this.duration);
    });
  }
  close(direction:"up"|"down"|undefined=undefined):Promise<void>{
    return new Promise((resolve)=>{
      this.status = "closing";
      this.direction = direction
      setTimeout(()=>{
        this.status = "close";
        resolve();
      },this.duration);
    });
  }
  refuse():Promise<void>{
    return new Promise((resolve)=>{
      this.rolling=true;
      setTimeout(()=>{
        this.rolling=false;
        resolve();
      },200);
    });
  }
  
  get fadeClass(){
    return {
      "close":this.status=="close",
      "opening":this.status=="opening",
      "open":this.status=="open",
      "closing":this.status=="closing",
    }
  }
  get wrapperClass(){
    let wrapperClass1:{
        "slide-up-in"?:boolean,
        "slide-down-in"?:boolean,
        "slide-up-out"?:boolean,
        "slide-down-out"?:boolean,
        rolling?:boolean,
    };
    if(this.status=="closing"){
      wrapperClass1 = {
        "slide-up-out":this.direction=="up",
        "slide-down-out":this.direction=="down",
      }
    }else if(this.status=="opening"){
      wrapperClass1 = {
        "slide-up-in":this.direction=="up",
        "slide-down-in":this.direction=="down",
      }
    }else{
      wrapperClass1 = {};
    }

    wrapperClass1.rolling=this.rolling;
    return wrapperClass1;
  }
  get overlayCSS(){
    return {
      "background":`rgba(0,0,0,${this.persistent?0.4:0})`,
      "--dialog-duration":this.duration/1000+"s",
    }
  }
  overlayClick(){
    if(!this.persistent){
      this.close()
    }
  }
}
